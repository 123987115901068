@font-face {
  font-family: akira-font;
  src: url("akira-expanded.03c99712.otf") format("opentype");
}

body {
  scroll-behavior: smooth;
  background-color: #11100f;
  overflow: hidden;
}

.hidden {
  transition: opacity 1s cubic-bezier(.455, .03, .515, .955);
  pointer-events: none !important;
  opacity: 0 !important;
}

header {
  pointer-events: none;
  z-index: 2;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
}

header.with-background {
  background-image: url("header.6efb8363.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.logo {
  background-position: top;
  background-repeat: no-repeat;
  background-size: 87.4%;
  width: 100%;
  height: 63.4vh;
  position: absolute;
  top: 10.46vw;
  left: 0%;
}

#logo-cut {
  background-image: url("logo-cut.37840339.png");
}

#logo-full {
  z-index: 8;
  background-image: url("logo-full.322886e2.png");
  transition: background-position 1s cubic-bezier(.455, .03, .515, .955), opacity 1s cubic-bezier(.455, .03, .515, .955);
  overflow: 1 !important;
}

#logo-full.centered {
  background-position: center;
}

.fade-out {
  opacity: 0 !important;
}

#logo {
  z-index: 6;
  background-image: url("logo.fce12964.png");
}

nav {
  background-color: #11100f00;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 0%;
  padding-top: 2.9%;
  padding-bottom: 2.9%;
  transition: background-color .5s cubic-bezier(.445, .05, .55, .95), padding-top .5s cubic-bezier(.445, .05, .55, .95), padding-bottom .5s cubic-bezier(.445, .05, .55, .95);
  display: flex;
  position: fixed;
  pointer-events: all !important;
}

nav.scrolled {
  background-color: #11100f;
  padding-top: 1.25%;
  padding-bottom: 1.25%;
}

nav a {
  color: #fff;
  padding: 0 1.62ch;
  font-family: Helvetica;
  font-size: 1.665vw;
  font-weight: lighter;
  text-decoration: none;
  transition: color .25s cubic-bezier(.445, .05, .55, .95);
}

nav a:hover {
  color: #ff6ae8;
}

#socials {
  float: right;
  margin-right: 2.5%;
  padding-top: 2.9%;
  padding-bottom: 2.9%;
  transition: background-color .5s cubic-bezier(.445, .05, .55, .95), padding-top .5s cubic-bezier(.445, .05, .55, .95), padding-bottom .5s cubic-bezier(.445, .05, .55, .95);
  position: fixed;
  right: 0;
}

#socials.scrolled {
  padding-top: 1.25%;
  padding-bottom: 1.25%;
}

#socials a {
  color: #fff;
  padding: 0 .5ch;
  font-family: Helvetica;
  font-size: 1.665vw;
  font-weight: lighter;
  text-decoration: none;
  pointer-events: all !important;
}

#socials a img {
  width: 1.665vw;
  height: 1.665vw;
}

#ca {
  float: left;
  color: #fff;
  background-color: #11100f;
  border: 1px solid #fff;
  border-radius: .5vw;
  margin-top: 2.9%;
  margin-bottom: 2.9%;
  margin-right: 2.5%;
  padding: 10px;
  font-family: Helvetica;
  font-size: 1vw;
  transition: background-color .25s cubic-bezier(.445, .05, .55, .95), color .25s cubic-bezier(.445, .05, .55, .95), margin-top .5s cubic-bezier(.445, .05, .55, .95), margin-bottom .5s cubic-bezier(.445, .05, .55, .95);
  position: fixed;
  left: 1.25%;
  pointer-events: all !important;
}

#ca.scrolled {
  margin-top: 1.25%;
  margin-bottom: 1.25%;
}

#ca:hover {
  color: #11100f;
  cursor: pointer;
  background-color: #ff6ae8;
}

main.with-background {
  margin-top: 31vw;
}

main {
  margin-top: 7.5vw;
}

#journey {
  color: #ff6ae8;
  height: 6.4vw;
  padding-top: 4.85%;
  padding-left: 13.3%;
  font-family: akira-font;
  font-size: 5.12vw;
  position: relative;
}

#journey.in-animation {
  z-index: 5;
}

.part-button {
  color: #fff;
  padding-left: 13.3%;
  font-family: akira-font;
  font-size: 2.5vw;
  text-decoration: none;
  transition: color .25s cubic-bezier(.445, .05, .55, .95);
  position: relative;
}

.part-button:hover {
  color: #ff6ae8;
}

#canvas {
  justify-content: center;
  align-items: center;
  display: flex;
}

canvas#lila-story {
  width: 75.64%;
  margin-left: 2.1%;
}

div.title {
  color: #11100f;
  background-color: #f27b01;
  margin-top: 4.2%;
  margin-left: 13.35%;
  margin-right: 11.2%;
  padding-top: .5%;
  padding-bottom: 1.05%;
  padding-left: 2.25%;
  font-family: akira-font;
  font-size: 3vw;
}

span.sep-line {
  background-color: #11100f;
  width: 28.9%;
  height: .1ch;
  margin-top: -.05%;
  margin-left: -1.65%;
  display: block;
}

div.story {
  color: #fff;
  hyphens: auto;
  margin: 2.6% 11% 3% 13.3%;
  font-family: Helvetica;
  font-size: 1.249vw;
  line-height: 3.12ch;
}

div.image-comic {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2.51%;
  margin-left: 13.3%;
  margin-right: 10.91%;
  display: flex;
}

div.image-comic img {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
}

div#black, div#black2 {
  z-index: 6;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

div#black2 {
  z-index: 4;
}

div.line {
  background-color: #fff;
  width: 75.45%;
  margin-top: 4.2%;
  margin-left: 13.35%;
  margin-right: 11.2%;
  padding-top: 1px;
  display: block;
}

div.end-title {
  color: #fff;
  text-align: center;
  margin-top: 1%;
  margin-left: 13.35%;
  margin-right: 11.2%;
  padding-top: .5%;
  padding-bottom: 1.05%;
  padding-left: 2.25%;
  font-family: akira-font;
  font-size: 3vw;
}

#character-carrousel {
  margin-top: 2.5%;
  margin-left: 13.3%;
  margin-right: 11.2%;
  display: flex;
}

.character-image img {
  max-width: 100%;
}
/*# sourceMappingURL=index.f654038b.css.map */
