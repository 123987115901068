/* add custom fonts */
@font-face {
	font-family: 'akira-font';
	src: url('../font/akira-expanded.otf') format('opentype');
}

body {
	background-color: #11100f;
	scroll-behavior: smooth;
	overflow: hidden;
}

.hidden {
	pointer-events: none !important;
	opacity: 0 !important;
	transition: opacity 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

header {
	height: 100%;
	margin: 0;
	padding: 0;
	top: 0;
	position: absolute;
	width: 100%;
	/* allow click through */
	pointer-events: none;
	z-index: 2;
}

header.with-background {
	background-image: url(../img/header.png);
	background-repeat: no-repeat;
	background-position: top;
	background-size: 100%;
}

.logo {
	position: absolute;
	top: 10.46vw;
	left: 0%;
	width: 100%;
	height: 63.4vh;
	background-size: 87.4%;
	background-repeat: no-repeat;
	background-position: top;
}
#logo-cut {
	background-image: url(../img/logo-cut.png);
}
#logo-full {
	background-image: url(../img/logo-full.png);
	overflow: 1 !important;
	transition: background-position 1s cubic-bezier(0.455, 0.03, 0.515, 0.955),
		opacity 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
	z-index: 8;
}
#logo-full.centered {
	background-position: center;
}

.fade-out {
	opacity: 0 !important;
}

#logo {
	background-image: url(../img/logo.png);
	z-index: 6;
}
nav {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	margin-right: 0%;
	padding-top: 2.9%;
	padding-bottom: 2.9%;
	position: fixed;
	width: 100%;
	background-color: #11100f00;
	pointer-events: all !important;
	transition: background-color 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95),
		padding-top 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95),
		padding-bottom 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

nav.scrolled {
	background-color: #11100fff;
	padding-top: 1.25%;
	padding-bottom: 1.25%;
}

nav a {
	text-decoration: none;
	color: white;
	font-size: 1.665vw;
	font-family: Helvetica;
	font-weight: lighter;
	padding: 0 1.62ch;
	transition: color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

nav a:hover {
	color: #ff6ae8;
}

#socials {
	position: fixed;
	padding-top: 2.9%;
	padding-bottom: 2.9%;
	float: right;
	right: 0;
	margin-right: 2.5%;
	transition: background-color 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95),
		padding-top 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95),
		padding-bottom 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

#socials.scrolled {
	padding-top: 1.25%;
	padding-bottom: 1.25%;
}

#socials a {
	text-decoration: none;
	color: white;
	font-size: 1.665vw;
	font-family: Helvetica;
	font-weight: lighter;
	padding: 0 0.5ch;
	pointer-events: all !important;
}

#socials a img {
	width: 1.665vw;
	height: 1.665vw;
}

#ca {
	position: fixed;
	margin-top: 2.9%;
	margin-bottom: 2.9%;
	float: left;
	left: 1.25%;
	margin-right: 2.5%;
	transition: background-color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95),
		color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95),
		margin-top 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95),
		margin-bottom 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	color: white;
	font-size: 1vw;
	font-family: 'Helvetica';

	/* border	 */
	border: 1px solid white;
	border-radius: 0.5vw;
	background-color: #11100f;
	padding: 10px;
	pointer-events: all !important;
}

#ca.scrolled {
	margin-top: 1.25%;
	margin-bottom: 1.25%;
}

#ca:hover {
	background-color: #ff6ae8;
	color: #11100f;
	cursor: pointer;
}

main.with-background {
	margin-top: 31vw;
}

main {
	margin-top: 7.5vw;
}

#journey {
	color: #ff6ae8;
	font-size: 5.12vw;
	font-family: 'akira-font';
	padding-left: 13.3%;
	padding-top: 4.85%;
	height: 6.4vw;
	position: relative;
}

#journey.in-animation {
	z-index: 5;
}

.part-button {
	color: white;
	font-size: 2.5vw;
	font-family: 'akira-font';
	padding-left: 13.3%;
	position: relative;
	text-decoration: none;
	transition: color 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

.part-button:hover {
	color: #ff6ae8;
}

#canvas {
	justify-content: center;
	align-items: center;
	display: flex;
}

canvas#lila-story {
	width: 75.64%;
	margin-left: 2.1%;
}

div.title {
	color: #11100f;
	font-size: 3vw;
	font-family: 'akira-font';
	background-color: #f27b01;
	margin-left: 13.35%;
	margin-right: 11.2%;
	margin-top: 4.2%;
	padding-bottom: 1.05%;
	padding-left: 2.25%;
	padding-top: 0.5%;
}

span.sep-line {
	background-color: #11100f;
	height: 0.1ch;
	margin-top: -0.05%;
	margin-left: -1.65%;
	width: 28.9%;
	display: block;
}
div.story {
	color: white;
	font-size: 1.249vw;
	font-family: 'Helvetica';
	line-height: 3.12ch;
	margin-top: 2.6%;
	margin-left: 13.3%;
	margin-right: 11%;
	margin-bottom: 3%;
	hyphens: auto;
}

div.image-comic {
	margin-left: 13.3%;
	margin-right: 10.91%;
	margin-top: 2.51%;
	flex-wrap: nowrap;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}
div.image-comic img {
	display: inline-block;
	position: relative;
	height: auto;
	width: 100%;
}

div#black,
div#black2 {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: black;
	z-index: 6;
}

div#black2 {
	z-index: 4;
}

div.line {
	background-color: white;
	margin-left: 13.35%;
	margin-right: 11.2%;
	margin-top: 4.2%;
	padding-top: 1px;
	width: 75.45%;
	display: block;
}

div.end-title {
	color: white;
	font-size: 3vw;
	font-family: 'akira-font';
	margin-left: 13.35%;
	margin-right: 11.2%;
	margin-top: 1%;
	padding-bottom: 1.05%;
	padding-left: 2.25%;
	padding-top: 0.5%;
	text-align: center;
}

#character-carrousel {
	display: flex;
	margin-top: 2.5%;
	margin-left: 13.3%;
	margin-right: 11.2%;
}

.character-image img {
	max-width: 100%;
}
